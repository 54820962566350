<template>
  <div class="mb-3">
    <CRow class="my-3">
        <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-end">
            <CButton
                color="excel"
                class="mr-1" 
                size="sm"
                @click="onBtnExport(true)"
            >
                <CIcon name="cil-file-excel"/>&nbsp; XSLX
            </CButton>
        </CCol>
    </CRow>

    <CRow class="mt-2">
        <CCol sm="12">
            <dataTableExtended
                class="align-center-row-datatable"
                :items="computedTransactionContainer"
                :fields="Transactionsfields"
                :items-per-page="5"
                column-filter
                pagination
                :table-filter="tableText.tableFilterText"
                :items-per-page-select="tableText.itemsPerPageText"
                :noItemsView="tableText.noItemsViewText"
                :loading="loading"
                sorter
            >
                <template #Status="{item}">
                    <td class="text-center">
                    <CBadge :color="getBadge(item.Status)">{{item.Status}}</CBadge>
                    </td>
                </template>
            
                <template #options="{ item }">
                    <td class="text-center">
                        <CButton
                        square
                        size="sm"
                        color="watch"
                        class="d-flex align-items-center"
                        @click="toggleContainerEdit(item)"
                        v-c-tooltip="{
                            content: $t('label.edit')+' '+$t('label.eirTransaction'),
                            placement: 'top-end'
                        }"
                        >
                        <CIcon name="eye" />
                        </CButton>
                    
                    </td>
                </template>
            </dataTableExtended>
        </CCol>
    </CRow>
  </div>
</template>
<script>
    import General from '@/_mixins/general';
    import { DateFormater } from '@/_helpers/funciones';
    import { mapState } from 'vuex';
    import ReportSearchContainer from '@/_mixins/reportsSearchContainer';
    import ENUM from '@/_store/enum';

    //data
    function data() {
        return {
            Items: [],
            loading: false,
            ModalBlContainer: false,
            ContainerItem:{},
            dataTransaction: [],
        };
    }

    //methods
    async function onBtnExport(valor) {
        let _lang = this.$i18n.locale;
        this.$store.state.containerSearch.loading = ENUM.LOADING;
        let tableHeader = {label:[], key:[]};
        let rowDataFormated = [];

        this.Transactionsfields.map(item=>{   
            tableHeader.label.push(item.label);
            tableHeader.key.push(item.key);
        })

        for (let i = 0; i < this.dataTransaction.length; i++) {
            rowDataFormated.push({
                RowNumber: this.dataTransaction[i].IdX ?? 'N/A',
                CraneAlias: this.dataTransaction[i].CraneAlias ?? 'N/A',
                BaySlot: this.dataTransaction[i].BaySlot ?? 'N/A',
                Transaccions: (_lang=='en' ? this.dataTransaction[i].ActivityEn : this.dataTransaction[i].ActivityEs) ?? 'N/A',
                MachineName: this.dataTransaction[i].MachineName ?? 'N/A',
                DeviceCode: this.dataTransaction[i].DeviceCod ?? 'N/A',
                FormatedDate: this.dataTransaction[i].TransactionDate ? DateFormater.formatDateTimeWithSlash(this.dataTransaction[i].TransactionDate) :  'N/A',
                TransaLogin: this.dataTransaction[i].TransaLogin ?? 'N/A',
                Status: this.dataTransaction[i].Status ?? 'N/A',
            });   
        }
        if(this.nulo!="null" && this.dataTransaction.length !== 0) {
            await this.generateExcel(rowDataFormated, this.$t('label.transactionHistory'), valor, tableHeader.label, false, tableHeader, 'SERVICIOS LOGISTICOS OCK', this.$store.state.containerSearch.dataContainer);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.$store.state.containerSearch.loading = ENUM.LOADED;
    }

    //computeds
    function computedTransactionContainer() {
        let _lang = this.$i18n.locale;
        return  this.dataTransaction.map((item) => Object.assign({}, item, {
            ...item,
            RowNumber: item.IdX ?? 'N/A',
            CraneAlias: item.CraneAlias ?? 'N/A',
            MachineName: item.MachineName ?? 'N/A',
            BaySlot: item.BaySlot ?? 'N/A',
            Transaccions: _lang=='en' ? item.ActivityEn : item.ActivityEs ?? 'N/A',
            DeviceCode: item.DeviceCod ?? 'N/A',
            FormatedDate: item.TransactionDate ? DateFormater.formatDateTimeWithSlash(item.TransactionDate) : 'N/A',
            TransaLogin: item.TransaLogin ?? 'N/A',
            YardName: item.YardName ?? '',
            TpTransacEirAcronym: item.TpTransacEirAcronym ?? '',
            _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        }));
    }

    function Transactionsfields(){
        return [
            { key: 'RowNumber', label: '#', _style: 'width:3%; text-align:center', _classes: 'text-center', filter: false },
            { label: this.$t('label.crane'), key: 'CraneAlias', _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { label: this.$t('label.machine'), key: 'MachineName', _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { label: this.$t('label.positionContainer'), key: 'BaySlot', _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { label: this.$t('label.yard'), key: 'YardName', _style:'width:10%;', _classes: 'text-uppercase center-cell text-center', sorter: true, filter: true},
            { label: this.$t('label.transaction'), key: 'Transaccions', _classes: 'text-uppercase text-center', _style: 'width:20%; text-align:center;', sorter: true, filter: true},
            { label: this.$t('label.transactionType'), key: 'TpTransacEirAcronym', _classes: 'text-uppercase text-center', _style: 'min-width:165px; text-align:center;', sorter: true, filter: true},
            { key: 'DeviceCode', label: this.$t('label.device'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
            { key: 'FormatedDate', label: this.$t('label.date'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'TransaLogin', label: this.$t('label.user'),  _style:'width:20%; text-align:center;', _classes:"center-cell text-center", sorter: true, filter: true },
            { key: 'Status', label: this.$t('label.status'),  _style:'width:17%; text-align:center;', _classes:"center-cell text-center" , sorter: true, filter: true},
        ];
    }
    

    export default{
        name: 'transactions-list',
        data,
        mixins: [General, ReportSearchContainer],
        components: {
        },
        methods: {
            onBtnExport,
        },
        computed: {
            Transactionsfields,
            computedTransactionContainer,
            ...mapState({
                dataContainer: state => state.containerSearch.dataContainer,
            })
        },
        watch:{
            dataContainer: function (newValue) {
                if (Object.keys(newValue).length > 0 && this.$store.state.containerSearch.dataContainer?.TransactionHistoryJson){
                    this.dataTransaction = this.$store.state.containerSearch.dataContainer?.TransactionHistoryJson;
                }
                else
                    this.dataTransaction = [];
            },
        }
    }
</script>
<style lang="scss">

    .icon{
        background-color: null; 
        border: 0px;
    }
    .center-cell {
        text-align: center;
    }
    .table-index {
        table {
            td {
            vertical-align: middle !important;
            }
        }
    }
</style>